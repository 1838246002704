import NewReleasesRounded from "@mui/icons-material/NewReleasesRounded";
import { motion } from "framer-motion";
import { sortBy } from "lodash/fp";
import { useCallback, useEffect, useMemo, useState } from "react";
import type { ComponentProps } from "react";
import type { Active, Block, BlockModel as BlockModelType, Fill, Position, PositionMap } from "@sunblocks/game";
import { toPositionMap } from "@sunblocks/game";
import { Fire, fireScale } from "../Fire";
import { Flower } from "../Flower";
import { Moon } from "../Moon";
import { MotionDiv } from "../Motion";
import { Sun, sunScale } from "../Sun";
import { Water, waterScale } from "../Water";
import { sizes } from "../sizes";
import { tailwindConfig } from "../tailwind-config";
import { howlerOptions, useHowler, useHowler16 } from "../use-howler";
import { useVibrate } from "../use-vibrate";
const {
  theme: {
    colors: {
      blue,
      gray,
      green,
      orange,
      pink,
      purple,
      red,
      stone,
      teal,
      yellow
    }
  }
} = tailwindConfig;
export const BlockModel = ({
  block,
  className,
  // HACK Just for Moon
  fillReal,
  // TODO Better name
  getSunIndex,
  onAnimationComplete,
  shape,
  animating = true,
  nCount = 0,
  immediate = false,
  night = false,
  fill = block.source ?? "unfilled",
  muted = false,
  pickPlaySinkSatisfied = ({
    playSinkSatisfied
  }) => playSinkSatisfied,
  ready = true,
  variants = {},
  active: activeProp,
  block: {
    n,
    sink,
    source,
    sunColor,
    weak,
    active: activeInitial = n || weak ? "inactive" : "active",
    mobile = !sink && !source
  },
  ...props
}: Omit<ComponentProps<typeof MotionDiv>, "animate" | "initial"> & {
  active?: Active;
  animate?: MaybeArray<string>;
  animating?: boolean;
  block: Omit<BlockModelType, "cell">;
  fill?: Fill;
  fillReal?: Fill;
  getSunIndex?: () => number;
  immediate?: boolean;
  initial?: MaybeArray<string>;
  muted?: boolean;
  nCount?: number;
  night?: boolean;
  pickPlaySinkSatisfied?: (sounds: {
    playSinkSatisfied: () => void;
    playSinkSatisfiedEnd: () => void;
    playSinkSatisfiedEndFull: () => void;
  }) => () => void;
  ready?: boolean;
  shape: Block["shape"];
}) => {
  const active = activeProp ?? activeInitial;
  const positions = useMemo(() => {
    const occupied = toPositionMap(shape, position => position, () => true as const);
    const positions = shape.flatMap(([y, x]) => [[y, x], occupied[y + 1]?.[x] && [y + 0.5, x], occupied[y]?.[x + 1] && [y, x + 0.5], occupied[y]?.[x + 1] && occupied[y + 1]?.[x] && occupied[y + 1]?.[x + 1] && [y + 0.5, x + 0.5]] satisfies (Position | undefined)[]).filter(Boolean);
    return !positions.length ? [[0, 0] satisfies Position] : positions;
  }, [shape]);
  const blockContentPosition = useMemo(() => {
    let sorted = [...positions];
    let positionMap: PositionMap<[number, number]>;
    const getScore = ([y, x]: Position) => [positionMap[y - 0.5]?.[x], positionMap[y + 0.5]?.[x], positionMap[y]?.[x - 0.5], positionMap[y]?.[x + 0.5]].filter(Boolean).length;
    while (sorted.length > 1) {
      positionMap = toPositionMap(sorted, position => position, position => position);
      sorted = sortBy(getScore, sorted);
      const score = getScore(sorted[0]!);
      while (sorted.length > 1 && getScore(sorted[0]!) <= score) {
        sorted.shift();
      }
    }
    return sorted[0] ?? [0, 0] satisfies Position;
  }, [positions]);
  const nSize = !n ? 2 : Math.max(2, Math.ceil(Math.sqrt(Math.max(n, nCount))));
  const height = useMemo(() => Math.max(...shape.map(([y]) => y)) + 1, [shape]);
  const width = useMemo(() => Math.max(...shape.map(([, x]) => x)) + 1, [shape]);
  const [fillSticky, setFillSticky] = useState(fill);
  useEffect(() => {
    if (fill !== fillReal) {
      return;
    }
    setFillSticky(fill);
  }, [fill, fillReal]);
  const animate = useMemo(() => [active !== "disactive" && active, active === "active" && fill, active === "active" && (fill === sink || fill === source) && animating && "animating", active === "disactive" && "disactive"].filter(Boolean), [active, animating, fill, sink, source]);
  const initial = useMemo(() => immediate ? animate : [activeInitial !== "disactive" && activeInitial, activeInitial === "active" && (source ?? "unfilled"), activeInitial === "disactive" && "disactive"].filter(Boolean), [activeInitial, animate, immediate, source]);
  const inert = activeInitial === "inactive" && !n && !weak;
  const [playBlockDrop] = useHowler({
    preload: !muted && Boolean(mobile || source || sink),
    ...howlerOptions.blockDrop
  });
  const [playBlockDropImmobile] = useHowler({
    preload: !muted && !mobile && !source && !sink,
    ...howlerOptions.blockDropImmobile
  });
  const [playBlockLift] = useHowler({
    preload: !muted,
    ...howlerOptions.blockLift
  });
  const [playNActive] = useHowler({
    preload: !muted && Boolean(n),
    ...howlerOptions.nActive
  });
  const [playNDisactive] = useHowler({
    preload: !muted && Boolean(n),
    ...howlerOptions.nDisactive
  });
  const [playNPlusOne] = useHowler({
    preload: !muted && Boolean(n),
    ...howlerOptions.nPlusOne
  });
  const [playWeakActive] = useHowler({
    preload: !muted && Boolean(weak),
    ...howlerOptions.weakActive
  });
  const [playWeakDisactive] = useHowler({
    preload: !muted && Boolean(weak),
    ...howlerOptions.weakDisactive
  });
  const playNodeFire = useVibrate(useHowler({
    // TODO We don't know if this will ever be fire unless we're passed that boolean from Level
    preload: !muted && active === "active",
    ...howlerOptions.nodeFire
  })[0], 50);
  const playNodeWater = useVibrate(useHowler({
    // TODO We don't know if this will ever be water unless we're passed that boolean from Level
    preload: !muted && active === "active",
    ...howlerOptions.nodeWater
  })[0], 50);
  const playSinkSatisfied = useVibrate(useHowler({
    preload: !muted && Boolean(sink),
    ...howlerOptions.sinkSatisfied
  })[0], 50);
  const playSinkSatisfiedWater = useVibrate(useHowler({
    preload: !muted && sink === "water",
    ...howlerOptions.sinkSatisfiedWater
  })[0], 50);
  const playSinkSatisfiedEnd = useVibrate(useHowler({
    preload: !muted && Boolean(sink),
    ...howlerOptions.sinkSatisfiedEnd
  })[0], 200);
  const playSinkSatisfiedEndFull = useVibrate(useHowler({
    preload: !muted && Boolean(sink),
    ...howlerOptions.sinkSatisfiedEndFull
  })[0], [200, 100, 200]);
  const [playBlockSunRaw] = useHowler16({
    preload: !muted && active === "active",
    ...howlerOptions.blockSun
  });
  const playBlockSun = useVibrate(useCallback(() => playBlockSunRaw[Math.max(0, Math.min(15, Math.round(getSunIndex?.() ?? 0)))]?.(), [getSunIndex, playBlockSunRaw]), 50);
  return <MotionDiv {...props} className={`pointer-events-none outline-offset-8 outline-blue-500 ${className}`} variants={{
    ...variants,
    hidden: {
      opacity: 0,
      scale: 0,
      ...variants?.hidden
    },
    visible: {
      opacity: 1,
      scale: 1,
      ...variants?.visible
    }
  }} onAnimationStartDelayed={{
    visible: () => variants?.hidden && typeof variants?.hidden !== "function" && variants.hidden.transition?.delay && (mobile || sink || source ? playBlockDrop : playBlockDropImmobile)(),
    hidden: () => playBlockLift()
  }} data-sentry-element="MotionDiv" data-sentry-component="BlockModel" data-sentry-source-file="index.tsx">
      <MotionDiv className="relative" initial={initial} animate={!ready ? [] : animate} style={{
      height: `${sizes.betweenBlockAndCell.rem * (height - 1) + sizes.block.rem}rem`,
      width: `${sizes.betweenBlockAndCell.rem * (width - 1) + sizes.block.rem}rem`
    }} onAnimationComplete={onAnimationComplete} onAnimationStart={!ready ? undefined : {
      ...(fill === sink || fill === source ? {} : {
        sun: () => playBlockSun(),
        fire: () => playNodeFire(),
        water: () => playNodeWater()
      }),
      active: () => fill && fill !== "unfilled" ? undefined : weak ? playWeakActive() : n !== undefined && nCount ? playNActive() : undefined,
      disactive: () => fill && fill !== "unfilled" ? undefined : weak ? playWeakDisactive() : n !== undefined && nCount ? playNDisactive() : undefined
    }} data-sentry-element="MotionDiv" data-sentry-source-file="index.tsx">
        {positions.map(([y, x]) => {
        const actual = Number.isInteger(y) && Number.isInteger(x);
        return <motion.div key={`${y}/${x}`} className={`pointer-events-auto absolute flex flex-col items-center justify-center ${mobile ? "rounded-xl" : !actual ? "" : "rounded-full"} ${sizes.block.className}`} style={{
          top: `${y * sizes.betweenBlockAndCell.rem}rem`,
          left: `${x * sizes.betweenBlockAndCell.rem}rem`,
          padding: `${sizes.distanceBetween.rem / 2}rem`,
          willChange: "auto"
        }} variants={{
          // @ts-expect-error -- TODO This isn't true???
          inactive: {
            backgroundColor: stone[500],
            ...(!inert && {
              scale: 1
            })
          },
          disactive: {
            backgroundColor: red[700],
            scale: 1
          },
          unfilled: sink === "unfilled" || night ? {
            backgroundColor: gray[800],
            scale: 1,
            // scale: [1, moonScale, 1],
            transition: {
              duration: immediate ? 0 : 0.35
            }
          } : {
            backgroundColor: green[500],
            scale: 1
          },
          sun: {
            backgroundColor: sunColor ?? yellow[400],
            scale: immediate || source === "sun" ? 1 : [1, sunScale, 1],
            transition: {
              duration: 0.35
            }
          },
          fire: {
            backgroundColor: orange[500],
            scale: immediate || source === "fire" ? 1 : [1, fireScale, 1],
            transition: {
              duration: 0.4
            }
          },
          water: {
            backgroundColor: blue[600],
            scale: immediate || source === "water" ? 1 : [1, waterScale, 1],
            transition: {
              duration: 0.6
            }
          },
          ...(n !== undefined ? {
            inactive: {
              backgroundColor: teal[500]
            }
          } : weak ? {
            inactive: {
              backgroundColor: pink[500]
            },
            disactive: {
              backgroundColor: pink[500]
            },
            unfilled: {
              backgroundColor: pink[500]
            },
            sun: {
              backgroundColor: pink[500]
            },
            fire: {
              backgroundColor: pink[500]
            },
            water: {
              backgroundColor: pink[500]
            }
          } : {})
        }}>
              {!weak ? <div className={`z-10 bg-stone-300 transition-opacity ${mobile ? "rounded-lg" : !actual ? "" : "rounded-full"} ${!inert ? "opacity-0" : "opacity-100"} ${sizes.blockContent.className}`} /> : <motion.div className={`z-10 ${mobile ? "rounded-xl" : !actual ? "" : "rounded-full"} ${sizes.blockInnerContent.className}`} variants={{
            inactive: {
              backgroundColor: purple[900],
              scale: 1
            },
            disactive: {
              backgroundColor: red[600],
              scale: 1
            },
            unfilled: {
              backgroundColor: green[400],
              scale: 1
            },
            sun: {
              backgroundColor: sunColor ?? yellow[300],
              scale: [1, sunScale, 1],
              transition: {
                duration: immediate ? 0 : 0.35
              }
            },
            fire: {
              backgroundColor: orange[400],
              scale: [1, fireScale, 1],
              transition: {
                duration: immediate ? 0 : 0.4
              }
            },
            water: {
              backgroundColor: blue[500],
              scale: [1, waterScale, 1],
              transition: {
                duration: immediate ? 0 : 0.6
              }
            }
          }} />}
            </motion.div>;
      })}
        <div className={`absolute z-10 flex flex-col items-center justify-center ${sizes.block.className}`} style={{
        top: `${blockContentPosition[0] * sizes.betweenBlockAndCell.rem}rem`,
        left: `${blockContentPosition[1] * sizes.betweenBlockAndCell.rem}rem`
      }}>
          {source === "sun" && <Sun />}
          {sink === "sun" && <Flower immediate={immediate} onAnimationStart={!ready ? undefined : {
          sun: pickPlaySinkSatisfied({
            playSinkSatisfied,
            playSinkSatisfiedEnd,
            playSinkSatisfiedEndFull
          })
        }} />}
          {(source === "fire" || sink === "water") && <Fire immediate={immediate} onAnimationStart={!ready ? undefined : {
          water: pickPlaySinkSatisfied({
            playSinkSatisfiedEnd,
            playSinkSatisfiedEndFull,
            playSinkSatisfied: playSinkSatisfiedWater
          })
        }} />}
          {source === "water" && <Water />}
          {sink === "unfilled" && <Moon animate={fillSticky} initial={immediate ? fillReal : fill} immediate={immediate} onAnimationStart={!ready ? undefined : {
          unfilled: pickPlaySinkSatisfied({
            playSinkSatisfied,
            playSinkSatisfiedEnd,
            playSinkSatisfiedEndFull
          })
        }} />}
          {!n ? null : <div className={`absolute flex flex-col justify-around ${sizes.blockInnerInnerContent.className}`}>
              {Array.from({
            length: nSize
          }).map((foo, y) => <div key={y} className="flex flex-row justify-around">
                  {Array.from({
              length: nSize
            }).map((foo, x) => <div key={`${y}/${x}`} className="relative">
                      <div className={`absolute size-3 -translate-x-1/2 -translate-y-1/2 rounded-full border-[0.125rem] border-stone-700 bg-gray-200 ${nSize * y + x >= n ? "opacity-0" : "opacity-100"}`} />
                      <MotionDiv className={`absolute size-3 ${nSize * y + x >= n ? "" : "rounded-full border-[0.125rem] border-stone-700 bg-teal-700"}`} style={{
                translateY: "-50%",
                translateX: "-50%"
              }} initial={!immediate || nSize * y + x >= nCount ? "hidden" : "visible"} animate={nSize * y + x >= nCount ? "hidden" : "visible"} variants={{
                hidden: {
                  opacity: 0,
                  scale: 8,
                  transition: immediate ? {
                    duration: 0
                  } : undefined
                },
                visible: {
                  opacity: 1,
                  scale: 1,
                  transition: immediate ? {
                    duration: 0
                  } : undefined
                }
              }} onAnimationStart={{
                visible: () => nSize * y + x < n && nCount < n && playNPlusOne()
              }}>
                        {nSize * y + x >= n && <NewReleasesRounded className="absolute size-8 -translate-x-1.5 -translate-y-1.5 text-yellow-300" />}
                      </MotionDiv>
                    </div>)}
                </div>)}
            </div>}
        </div>
      </MotionDiv>
    </MotionDiv>;
};