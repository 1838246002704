import { sumBy } from "lodash/fp";

export type Coordinate = {
  left: number;
  top: number;
};

export const sumCoordinates = (...coordinates: Coordinate[]) => ({
  top: sumBy(({ top }) => top, coordinates),
  left: sumBy(({ left }) => left, coordinates),
});
