import RedoRounded from "@mui/icons-material/RedoRounded";
import ReplayRounded from "@mui/icons-material/ReplayRounded";
import UndoRounded from "@mui/icons-material/UndoRounded";
import { motion, useMotionValue, useTransform } from "framer-motion";
import type { MotionValue } from "framer-motion";
import { flatMap, groupBy, keyBy, mapValues, pick, sortBy } from "lodash/fp";
import { memo, useCallback, useEffect, useMemo, useState } from "react";
import type { ComponentProps } from "react";
import { getActiveByKey, getActiveNodes, getAdjacentBlocksByKey, getAdjacentNodesByKey, getBlockByPosition, getBlockPositionByKey, getCellByPosition, getFillByKey, getFillsByKey, getNodesByFill, getNodesByFillOfSource, getSinksByFill, getSourcesByFill, getValidPositions, isWin, optimizeMoveSet, resolveModels, sumPositions, toPositionMap } from "@sunblocks/game";
import type { Area, Block, Cell, Fill, Level as LevelType, Move, Node, Position } from "@sunblocks/game";
import { unflow } from "@sunblocks/utils";
import { useBackground } from "../Background";
import { BlockModel } from "../BlockModel";
import { Button } from "../Button";
import { ButtonRow } from "../ButtonRow";
import { CellModel } from "../CellModel";
import { Fire } from "../Fire";
import { Flower } from "../Flower";
import { Moon } from "../Moon";
import { MotionDiv } from "../Motion";
import { startTabIndex } from "../TopControls";
import { sizes } from "../sizes";
import { useDocumentVisibility } from "../use-document-visibility-state";
import { howlerOptions, useHowler } from "../use-howler";
import { useRemPx } from "../use-rem-px";
import { useVibrate } from "../use-vibrate";
import { useWindowDimensions } from "../use-window-dimensions";
import { useWindowFocus } from "../use-window-focus";
import { useKeyboardController } from "./use-keyboard-controller";
import { useMoveHintsController } from "./use-move-hints-controller";
import { usePointerController } from "./use-pointer-controller";
import { sumCoordinates } from "./utils";
import type { Coordinate } from "./utils";
const emptyArray = [] satisfies Move[];
const defaultMoveSet = [[], []] satisfies [Move[], Move[]];
const CounterIcon = ({
  animating,
  fill,
  incrementWidthPx,
  num,
  offsetPx,
  sink,
  width
}: {
  animating: boolean;
  fill: Fill;
  incrementWidthPx: number;
  num: number;
  offsetPx: number;
  sink: "sun" | "unfilled" | "water";
  width: MotionValue<number>;
}) => {
  const SinkIcon = useMemo(() => sink === "sun" ? Flower : sink === "water" ? Fire : Moon, [sink]);
  const opacity = useTransform(width, [offsetPx + incrementWidthPx * (num - 7), offsetPx + incrementWidthPx * num, offsetPx + incrementWidthPx * (num + 1)], [0, 1, 0]);
  const scale = useTransform(width, [offsetPx + incrementWidthPx * (num - 2), offsetPx + incrementWidthPx * (num - 1), offsetPx + incrementWidthPx * num, offsetPx + incrementWidthPx * (num + 1)], [0.7, 0.8, 1, 0]);
  return <SinkIcon immediate animate={[fill, animating && "animating"].filter(Boolean)} style={{
    opacity,
    scale
  }} data-sentry-element="SinkIcon" data-sentry-component="CounterIcon" data-sentry-source-file="index.tsx" />;
};
const LevelPure = ({
  className,
  level,
  onBack,
  onHiddenDone,
  onVisibleDone,
  onWin,
  onWinDone,
  personalBestScore,
  animating = true,
  bestScore = Infinity,
  canInteract = true,
  controls = true,
  counter = true,
  immediate = false,
  muted: mutedProp = false,
  visible = true,
  moveHints = [],
  moveSet: [movesProp, redoMoves] = defaultMoveSet,
  setMoveSet = () => {},
  area: {
    background: areaBackground,
    models: areaModels
  } = {},
  level: {
    blocks,
    cells,
    gridHeight,
    gridWidth,
    models: levelModels,
    background = areaBackground
  },
  ...props
}: ComponentProps<"div"> & {
  animating?: boolean;
  area?: Pick<Area, "background" | "models">;
  bestScore?: number;
  canInteract?: boolean;
  controls?: boolean;
  counter?: boolean;
  immediate?: boolean;
  level: Pick<LevelType, "background" | "blocks" | "cells" | "gridHeight" | "gridWidth" | "models">;
  moveHints?: Move[];
  moveSet?: [Move[], Move[]];
  muted?: boolean;
  onBack?: () => void;
  onHiddenDone?: () => void;
  onVisibleDone?: () => void;
  onWin?: (moves: Move[]) => void;
  onWinDone?: () => void;
  personalBestScore?: number;
  setMoveSet?: (action: ((value: [Move[], Move[]] | undefined) => [Move[], Move[]]) | [Move[], Move[]]) => void;
  visible?: boolean;
}) => {
  useBackground(background, {
    immediate
  });
  const [visibleDone, setVisibleDone] = useState(false);
  const [lockedInMoves, setLockedInMoves] = useState<typeof movesProp>();
  const moves = useMemo(() => lockedInMoves ?? (visibleDone || immediate ? movesProp : emptyArray), [immediate, lockedInMoves, movesProp, visibleDone]);
  useEffect(() => {
    if (lockedInMoves) {
      if (visible || !visibleDone) {
        setLockedInMoves(undefined);
      }
      return;
    }
    if (visible || !visibleDone) {
      return;
    }
    setLockedInMoves(moves);
  }, [lockedInMoves, moves, visible, visibleDone]);
  const positionToCoordinate = useCallback((position: Position): Coordinate => ({
    top: sizes.betweenBlockAndCell.rem * (position[0] + 0.5 - gridHeight / 2),
    left: sizes.betweenBlockAndCell.rem * (position[1] + 0.5 - gridWidth / 2)
  }), [gridHeight, gridWidth]);
  const blockPositionByKey = useMemo(() => getBlockPositionByKey({
    blocks
  }, moves), [blocks, moves]);
  const blockByPosition = useMemo(() => getBlockByPosition({
    blocks
  }, {
    blockPositionByKey
  }), [blockPositionByKey, blocks]);
  const adjacentBlocksByKey = useMemo(() => getAdjacentBlocksByKey({
    blocks
  }, {
    blockByPosition,
    blockPositionByKey
  }), [blockByPosition, blockPositionByKey, blocks]);
  const cellByPosition = useMemo(() => getCellByPosition({
    cells
  }), [cells]);
  const adjacentNodesByKey = useMemo(() => getAdjacentNodesByKey({
    blocks,
    cells
  }, {
    adjacentBlocksByKey,
    blockByPosition,
    blockPositionByKey,
    cellByPosition
  }), [adjacentBlocksByKey, blockByPosition, blockPositionByKey, blocks, cellByPosition, cells]);
  const activeByKey = useMemo(() => getActiveByKey({
    blocks,
    cells
  }, {
    adjacentBlocksByKey,
    adjacentNodesByKey
  }), [adjacentBlocksByKey, adjacentNodesByKey, blocks, cells]);
  const activeNodes = useMemo(() => getActiveNodes({
    blocks,
    cells
  }, {
    activeByKey
  }), [activeByKey, blocks, cells]);
  const fillsByKey = useMemo(() => getFillsByKey({
    activeByKey,
    activeNodes,
    adjacentNodesByKey
  }), [activeByKey, activeNodes, adjacentNodesByKey]);
  const fillByKey = useMemo(() => getFillByKey({
    fillsByKey
  }), [fillsByKey]);
  const nodesByFill = useMemo(() => getNodesByFill({
    activeNodes,
    fillByKey
  }), [activeNodes, fillByKey]);
  const sinksByFill = useMemo(() => getSinksByFill({
    blocks,
    cells
  }), [blocks, cells]);
  const won = useMemo(() => isWin({
    nodesByFill,
    sinksByFill
  }), [nodesByFill, sinksByFill]);
  const windowDimensions = useWindowDimensions();
  const scale = useMemo(() => Math.min(1, (windowDimensions.height / 16 - 2 * sizes.menu.rem - 2 * sizes.distanceBetween.rem) / (sizes.betweenBlockAndCell.rem * gridHeight), (windowDimensions.width / 16 - 2 * sizes.distanceBetween.rem) / (sizes.betweenBlockAndCell.rem * gridWidth)), [gridHeight, gridWidth, windowDimensions.height, windowDimensions.width]);
  const remPx = useRemPx(scale);
  const sourcesByFill = useMemo(() => getSourcesByFill({
    blocks,
    cells
  }), [blocks, cells]);
  const [visibleNodesByFillRaw, setVisibleNodesByFill] = useState(() => immediate ? nodesByFill : {
    ...sourcesByFill,
    unfilled: activeNodes.difference(sourcesByFill.fire).difference(sourcesByFill.sun).difference(sourcesByFill.water)
  });
  const visibleNodesByFill = immediate ? nodesByFill : visibleNodesByFillRaw;
  const visibilityState = useDocumentVisibility();
  const windowFocus = useWindowFocus();
  const muted = mutedProp || visibilityState !== "visible" || !windowFocus;
  const playBlockDrop = useVibrate(useHowler({
    preload: !muted,
    ...howlerOptions.blockDrop
  })[0], 50);
  const [consideringController, setConsideringController] = useState<string>();
  const [consideredBlock, setConsideredBlock] = useState<Block>();
  const [consideredPosition, setConsideredPosition] = useState<Position>();
  const onBlockCommit = useCallback(() => {
    if (!consideredBlock || !consideredPosition) {
      return;
    }
    setMoveSet(moveSet => optimizeMoveSet(level, {
      _key: consideredBlock._key,
      position: consideredPosition
    }, moveSet));
    playBlockDrop();
    setConsideringController(undefined);
    setConsideredBlock(undefined);
    setConsideredPosition(undefined);
  }, [consideredBlock, consideredPosition, level, playBlockDrop, setMoveSet]);
  const consideredMoves = useMemo(() => !consideredBlock || !consideredPosition ? moves : [...moves, {
    _key: consideredBlock._key,
    position: consideredPosition
  }], [consideredBlock, consideredPosition, moves]);
  const consideredBlockPositionByKey = useMemo(() => consideredMoves === moves ? blockPositionByKey : getBlockPositionByKey({
    blocks
  }, consideredMoves), [blockPositionByKey, blocks, consideredMoves, moves]);
  const consideredBlockByPosition = useMemo(() => consideredMoves === moves ? blockByPosition : getBlockByPosition({
    blocks
  }, {
    blockPositionByKey: consideredBlockPositionByKey
  }), [blockByPosition, blocks, consideredBlockPositionByKey, consideredMoves, moves]);
  const consideredAdjacentBlocksByKey = useMemo(() => consideredMoves === moves ? adjacentBlocksByKey : getAdjacentBlocksByKey({
    blocks
  }, {
    blockByPosition: consideredBlockByPosition,
    blockPositionByKey: consideredBlockPositionByKey
  }), [adjacentBlocksByKey, blocks, consideredBlockByPosition, consideredBlockPositionByKey, consideredMoves, moves]);
  const consideredAdjacentNodesByKey = useMemo(() => consideredMoves === moves ? adjacentNodesByKey : getAdjacentNodesByKey({
    blocks,
    cells
  }, {
    cellByPosition,
    adjacentBlocksByKey: consideredAdjacentBlocksByKey,
    blockByPosition: consideredBlockByPosition,
    blockPositionByKey: consideredBlockPositionByKey
  }), [adjacentNodesByKey, blocks, cellByPosition, cells, consideredAdjacentBlocksByKey, consideredBlockByPosition, consideredBlockPositionByKey, consideredMoves, moves]);
  const consideredActiveByKey = useMemo(() => consideredMoves === moves ? activeByKey : getActiveByKey({
    blocks,
    cells
  }, {
    adjacentBlocksByKey: consideredAdjacentBlocksByKey,
    adjacentNodesByKey: consideredAdjacentNodesByKey
  }), [activeByKey, blocks, cells, consideredAdjacentBlocksByKey, consideredAdjacentNodesByKey, consideredMoves, moves]);
  const consideredActiveNodes = useMemo(() => consideredMoves === moves ? activeNodes : getActiveNodes({
    blocks,
    cells
  }, {
    activeByKey: consideredActiveByKey
  }), [activeNodes, blocks, cells, consideredActiveByKey, consideredMoves, moves]);
  const consideredFillsByKey = useMemo(() => consideredMoves === moves ? fillsByKey : getFillsByKey({
    activeByKey: consideredActiveByKey,
    activeNodes: consideredActiveNodes,
    adjacentNodesByKey: consideredAdjacentNodesByKey
  }), [consideredActiveByKey, consideredActiveNodes, consideredAdjacentNodesByKey, consideredMoves, fillsByKey, moves]);
  const consideredNodesByFill = useMemo(() => consideredMoves === moves ? nodesByFill : getNodesByFill({
    activeNodes: consideredActiveNodes,
    fillByKey: getFillByKey({
      fillsByKey: consideredFillsByKey
    })
  }), [consideredActiveNodes, consideredFillsByKey, consideredMoves, moves, nodesByFill]);
  const nodesByFillOfSource = useMemo(() => getNodesByFillOfSource({
    activeNodes,
    fillsByKey
  }), [activeNodes, fillsByKey]);
  const consideredNodesByFillOfSource = useMemo(() => consideredMoves === moves ? nodesByFillOfSource : getNodesByFillOfSource({
    activeNodes: consideredActiveNodes,
    fillsByKey: consideredFillsByKey
  }), [consideredActiveNodes, consideredFillsByKey, consideredMoves, moves, nodesByFillOfSource]);
  const animatingNodesByFill = useMemo(() => !visible || !visibleDone || immediate ? visibleNodesByFill : unflow(["fire", "sun", "water"] satisfies Fill[], keyBy(fill => fill), mapValues(fill => (consideredBlock ? visibleNodesByFill[fill] : [...visibleNodesByFill[fill]].reduce((acc, {
    _key
  }) => acc.union(adjacentNodesByKey[_key]!), visibleNodesByFill[fill])).union(sourcesByFill[fill]).intersection(consideredNodesByFillOfSource[fill])), pick(["fire", "sun", "water"]), ({
    fire,
    sun,
    water
  }) => ({
    water,
    fire: fire.difference(water),
    sun: sun.difference(water).difference(fire)
  })), [adjacentNodesByKey, consideredBlock, consideredNodesByFillOfSource, immediate, sourcesByFill, visible, visibleDone, visibleNodesByFill]);
  const [hoveredBlock, setHoveredBlock] = useState<Block>();
  const onBlockHover = useCallback((block: Block | undefined) => {
    setHoveredBlock(block);
  }, []);
  const possibleBlock = consideredBlock ?? hoveredBlock;
  const validPositions = useMemo(() => !possibleBlock ? [] : getValidPositions(possibleBlock, blockPositionByKey[possibleBlock._key]!, {
    blockByPosition,
    cellByPosition
  }), [blockByPosition, blockPositionByKey, cellByPosition, possibleBlock]);
  const validPositionMap = useMemo(() => toPositionMap(validPositions, position => position, () => true as const), [validPositions]);
  const playBlockLift = useVibrate(useHowler({
    preload: !muted,
    ...howlerOptions.blockLift
  })[0], 50);
  const onBlockConsider = useCallback((...args: [] | [controller: string, block: Block, position: Position]) => {
    if (args.length === 0) {
      if (consideredBlock) {
        playBlockDrop();
      }
      setConsideringController(undefined);
      setConsideredBlock(undefined);
      setConsideredPosition(undefined);
      return;
    }
    const [controller, block, position] = args;
    if (consideredBlock !== block) {
      if (consideredBlock) {
        onBlockCommit();
      }
      playBlockLift();
      // "Locks in" currently animating nodes so they don't "revert" when grabbing things
      setVisibleNodesByFill(visibleNodesByFill => ({
        ...animatingNodesByFill,
        unfilled: visibleNodesByFill.unfilled
      }));
    }
    setConsideringController(controller);
    setConsideredBlock(block);
    const usableValidPositionMap = consideredBlock === block ? validPositionMap : toPositionMap(getValidPositions(block, blockPositionByKey[block._key]!, {
      blockByPosition,
      cellByPosition
    }), position => position, () => true as const);
    setConsideredPosition(consideredPosition => consideredPosition?.[0] === position[0] && consideredPosition?.[1] === position[1] || !usableValidPositionMap?.[position[0]]?.[position[1]] ? consideredPosition : position);
  }, [animatingNodesByFill, blockByPosition, blockPositionByKey, cellByPosition, consideredBlock, onBlockCommit, playBlockDrop, playBlockLift, validPositionMap]);
  const {
    controlledBlocks: pointerControlledBlocks,
    blockProps: blockPropsForPointer
  } = usePointerController({
    consideredBlock,
    consideringController,
    onBlockCommit,
    onBlockConsider,
    onBlockHover,
    positionToCoordinate,
    remPx,
    consideredBlockValidPositions: validPositions,
    consideredBlockCoordinate: consideredBlock && positionToCoordinate(blockPositionByKey[consideredBlock._key]!)
  });
  const {
    controlledBlocks: moveHintsControlledBlocks
  } = useMoveHintsController({
    blocks,
    blockPositionByKey,
    consideredBlock,
    consideringController,
    moveHints,
    onBlockConsider,
    ready: visibleDone
  });
  const controlledBlocks = useMemo(() => ({
    ...moveHintsControlledBlocks,
    ...pointerControlledBlocks
  }), [moveHintsControlledBlocks, pointerControlledBlocks]);

  /**
   * Negative numbers move from moves to redoMoves, ie (undo)
   *
   * Positive numbers move from redoMoves to moves, ie (redo)
   */
  const undoRedo = useCallback((numMovesRaw: number) => {
    const numMoves = Math.max(-moves.length, Math.min(redoMoves.length, numMovesRaw));
    if (numMoves === 0 || numMoves < 0 && moves.length === 0 || numMoves > 0 && redoMoves.length === 0) {
      return;
    }
    const nextMoveSet = (numMoves < 0 ? [moves.slice(0, numMoves), [...moves.slice(numMoves), ...redoMoves]] : [[...moves, ...redoMoves.slice(0, numMoves)], redoMoves.slice(numMoves)]) satisfies [Move[], Move[]];
    onBlockConsider();
    setMoveSet(nextMoveSet);
  }, [moves, onBlockConsider, redoMoves, setMoveSet]);
  const {
    blockProps: blockPropsForKeyboard
  } = useKeyboardController({
    consideredBlock,
    consideringController,
    level,
    onBlockCommit,
    onBlockConsider,
    onBlockHover,
    undoRedo,
    tabIndexOffset: startTabIndex
  });

  // HACK Not sure why it fires more than once, not sure why I need to split this into two useEffects
  const [firedOnWin, setFiredOnWin] = useState(false);
  useEffect(() => {
    if (firedOnWin) {
      if (!won) {
        setFiredOnWin(false);
        return;
      }
      return;
    }
    if (!won) {
      return;
    }
    setFiredOnWin(true);
    setHoveredBlock(undefined);
    onBlockConsider();
    onWin?.(moves);
  }, [firedOnWin, moves, onBlockConsider, onWin, won]);
  const [playBackgroundSound, {
    fade: fadeBackgroundSound,
    stop: stopBackgroundSound
  }] = useHowler({
    src: `/level-background-${background && ["fire", "night"].includes(background) ? background : "default"}.wav`,
    preload: !mutedProp,
    ...howlerOptions.levelBackground
  });
  useEffect(() => {
    playBackgroundSound();
    return () => {
      stopBackgroundSound();
    };
  }, [playBackgroundSound, stopBackgroundSound]);
  useEffect(() => {
    if (muted || !visible || !visibleDone) {
      return () => {};
    }
    fadeBackgroundSound(0, 0.2, 2000);
    return () => {
      fadeBackgroundSound(0.2, 0, 1000);
    };
  }, [fadeBackgroundSound, muted, visible, visibleDone]);
  const highlighted = useMemo(() => !possibleBlock ? {} : toPositionMap(validPositions.flatMap(position => possibleBlock.shape.map(shapePosition => sumPositions(position, shapePosition))), position => position, () => true as const), [possibleBlock, validPositions]);
  const shadow = useMemo(() => !consideredPosition || !consideredBlock ? {} : toPositionMap(consideredBlock.shape, position => sumPositions(position, consideredPosition), () => true as const), [consideredBlock, consideredPosition]);
  const [firedOnWinDone, setFiredOnWinDone] = useState(false);
  useEffect(() => {
    const leave = !won || !isWin({
      sinksByFill,
      nodesByFill: visibleNodesByFill
    });
    if (firedOnWinDone) {
      if (leave) {
        setFiredOnWinDone(false);
        return;
      }
      return;
    }
    if (leave) {
      return;
    }
    setFiredOnWinDone(true);
    onWinDone?.();
  }, [firedOnWinDone, onWinDone, setFiredOnWin, sinksByFill, visibleNodesByFill, won]);
  const visibleFillByKey = useMemo(() => unflow(visibleNodesByFill, Object.entries<(typeof visibleNodesByFill)[keyof typeof visibleNodesByFill]>, flatMap(([fill, set]) => [...set].map(({
    _key
  }) => [_key, fill as keyof typeof visibleNodesByFill] satisfies [string, keyof typeof visibleNodesByFill])), Object.fromEntries<keyof typeof visibleNodesByFill>), [visibleNodesByFill]);

  // While moving around the consideredBlock, we need all the consideredNodesByFill.unfilled to "stick"
  useEffect(() => setVisibleNodesByFill(visibleNodesByFill => {
    const intersection = {
      unfilled: consideredNodesByFill.unfilled,
      fire: visibleNodesByFill.fire.intersection(animatingNodesByFill.fire),
      sun: visibleNodesByFill.sun.intersection(animatingNodesByFill.sun),
      water: visibleNodesByFill.water.intersection(animatingNodesByFill.water)
    };
    return intersection.fire.size === visibleNodesByFill.fire.size && intersection.sun.size === visibleNodesByFill.sun.size && intersection.water.size === visibleNodesByFill.water.size ? visibleNodesByFill : intersection;
  }), [animatingNodesByFill.fire, animatingNodesByFill.sun, animatingNodesByFill.water, consideredBlock, consideredNodesByFill.unfilled, setVisibleNodesByFill]);
  const animatingFillByKey = useMemo(() => unflow(animatingNodesByFill, Object.entries<(typeof animatingNodesByFill)[keyof typeof animatingNodesByFill]>, flatMap(([fill, set]) => [...set].map(({
    _key
  }) => [_key, fill as keyof typeof animatingNodesByFill] satisfies [string, keyof typeof animatingNodesByFill])), Object.fromEntries<keyof typeof animatingNodesByFill>), [animatingNodesByFill]);

  // Active nodes that are either not satisfied or are sinks that aren't right
  const visuallyUnsatisfiedActiveNodes = useMemo(() => [...activeNodes.difference(visibleNodesByFill.sun).difference(visibleNodesByFill.fire).difference(visibleNodesByFill.water).difference(sinksByFill.unfilled).union(sinksByFill.sun.difference(visibleNodesByFill.sun)).union(sinksByFill.unfilled.difference(visibleNodesByFill.unfilled)).union(sinksByFill.water.difference(visibleNodesByFill.water))], [activeNodes, sinksByFill.sun, sinksByFill.unfilled, sinksByFill.water, visibleNodesByFill.fire, visibleNodesByFill.sun, visibleNodesByFill.unfilled, visibleNodesByFill.water]);
  const doneAfterAnimating = useMemo(() => isWin({
    sinksByFill,
    nodesByFill: {
      ...visibleNodesByFill,
      ...animatingNodesByFill
    }
  }), [animatingNodesByFill, sinksByFill, visibleNodesByFill]);
  const visibleDelay = useMemo(() => {
    const gapBetweenCells = 0.04;
    const gapBetweenBlocks = 0.05;
    const gapBetweenSets = 0.75;
    const initialDelay = 0.5;
    const cellDelay = ({
      position: [y, x]
    }: Pick<Cell, "position">) => initialDelay + gapBetweenCells * (y + x);
    const cellVisibleDelay = unflow(cells, keyBy(({
      _key
    }) => _key), mapValues(cellDelay));
    const cellsDoneAfter = cellDelay({
      position: [gridHeight, gridWidth]
    });
    const blocksSet = new Set(blocks);
    const n = new Set([...blocks].filter(({
      n
    }) => n));
    const sets = [blocksSet.intersection(sourcesByFill.sun.union(sinksByFill.sun) as Set<Block>), blocksSet.intersection(sourcesByFill.water.union(sinksByFill.water) as Set<Block>), blocksSet.intersection(sinksByFill.unfilled.union(sinksByFill.unfilled) as Set<Block>), n, new Set([...blocks].filter(({
      mobile
    }) => !mobile)), blocksSet];
    return {
      ...cellVisibleDelay,
      ...sets.map((set, index) => sets.slice(0, index).reduce((set, previousSet) => set.difference(previousSet), set)).filter(({
        size
      }) => size).reduce(({
        previousDelay,
        obj
      }, set) => {
        const nodeGroups = unflow([...set], groupBy(({
          shape,
          initialPosition: [y, x]
        }) => y + x + (Math.max(...shape.map(([y]) => y)) + Math.max(...shape.map(([, x]) => x))) / 2), Object.entries<Block[]>);
        return {
          previousDelay: previousDelay + gapBetweenSets + gapBetweenBlocks * nodeGroups.length,
          obj: {
            ...obj,
            ...unflow(nodeGroups, sortBy(([distance]) => Number(distance)),
            // I would have used lodash/fp/flatMap, but I want the index and I was lazy
            nodeGroups => nodeGroups.flatMap(([, nodes], nodeGroupIndex) => nodes.map(({
              _key
            }) => [_key, previousDelay + gapBetweenBlocks * nodeGroupIndex] satisfies [string, number])), Object.fromEntries<number>)
          }
        };
      }, {
        previousDelay: cellsDoneAfter + gapBetweenSets,
        obj: {} as {
          [id: string]: number;
        }
      }).obj
    };
  }, [blocks, cells, gridHeight, gridWidth, sinksByFill.sun, sinksByFill.unfilled, sinksByFill.water, sourcesByFill.sun, sourcesByFill.water]);
  const hiddenDelay = useMemo(() => {
    const gapBetweenNodes = 0.05;
    const gapBetweenSets = 0.75;
    const initialDelay = 0.5;
    const nodesSet = new Set([...blocks, ...cells]);
    const sets = [nodesSet.difference(nodesByFill.fire).difference(nodesByFill.sun).difference(nodesByFill.water).difference(sinksByFill.sun).difference(sinksByFill.unfilled).difference(sinksByFill.water), nodesSet];
    return sets.map((set, index) => sets.slice(0, index).reduce((set, previousSet) => set.difference(previousSet), set)).filter(({
      size
    }) => size).reduce(({
      previousDelay,
      obj
    }, set) => {
      const nodeGroups = unflow([...set], groupBy(node => {
        if ("initialPosition" in node) {
          const {
            _key,
            shape
          } = node;
          const [y, x] = blockPositionByKey[_key]!;
          return y + x + (Math.max(...shape.map(([y]) => y)) + Math.max(...shape.map(([, x]) => x))) / 2;
        }
        const {
          position: [y, x]
        } = node;
        return y + x;
      }), Object.entries<Node[]>);
      return {
        previousDelay: previousDelay + gapBetweenSets + gapBetweenNodes * nodeGroups.length,
        obj: {
          ...obj,
          ...unflow(nodeGroups, sortBy(([distance]) => Number(distance)),
          // I would have used lodash/fp/flatMap, but I want the index and I was lazy
          nodeGroups => nodeGroups.flatMap(([, nodes], nodeGroupIndex) => nodes.map(({
            _key
          }) => [_key, previousDelay + gapBetweenNodes * nodeGroupIndex] satisfies [string, number])), Object.fromEntries<number>)
        }
      };
    }, {
      previousDelay: initialDelay,
      obj: {} as {
        [id: string]: number;
      }
    }).obj;
  }, [blockPositionByKey, blocks, cells, nodesByFill.fire, nodesByFill.sun, nodesByFill.water, sinksByFill.sun, sinksByFill.unfilled, sinksByFill.water]);
  const spacerWidth = useMotionValue(0);
  const {
    fill: wonBestModelFill = "sun",
    model: {
      sink: wonBestModelSink = "sun"
    } = {}
  } = useMemo(() => resolveModels("wonBest", areaModels, levelModels), [areaModels, levelModels]);
  return <div {...props} className={`flex size-full items-center justify-center overflow-hidden ${className}`} style={{
    ...props,
    height: windowDimensions.height
  }} data-sentry-component="LevelPure" data-sentry-source-file="index.tsx">
      {counter && <div className={`pointer-events-none fixed top-0 flex w-full items-center justify-center ${immediate ? "" : "transition-opacity duration-[3s]"} ${!visible || !visibleDone ? "opacity-0" : "opacity-100"}`}>
          <div className="flex flex-row items-center justify-center overflow-hidden" style={{
        height: sizes.menu.rem * 16,
        width: 11 * sizes.control.rem * 16
      }}>
            <motion.div className="flex-shrink-0" style={{
          width: sizes.control.rem * (Math.min(bestScore, moves.length + redoMoves.length + 6) + 1) * 16
        }} />
            <motion.div className="flex flex-row items-center justify-center" style={{
          scale: Math.max(0, sizes.control.rem / sizes.blockContent.rem * 16 / remPx)
        }}>
              {Array.from({
            length: Math.min(bestScore, moves.length + redoMoves.length + 6)
          }).map((_, num) => <CounterIcon key={num} animating={animating && visibleDone} fill={wonBestModelFill} incrementWidthPx={2 * sizes.control.rem * 16} num={num} offsetPx={2 * sizes.control.rem * 16} sink={wonBestModelSink} width={spacerWidth} />)}
            </motion.div>
            <motion.div className="flex-shrink-0" initial={{
          width: 0
        }} animate={{
          width: (2 * moves.length + (consideredBlock ? 1.25 : 0)) * sizes.control.rem * 16,
          transition: immediate ? {
            duration: 0
          } : {}
        }} style={{
          width: spacerWidth
        }} />
          </div>
        </div>}
      <MotionDiv className={`relative drop-shadow-lg ${visibleDone && canInteract ? "" : "pointer-events-none"}`} initial={immediate && visible ? "visible" : "hidden"} animate={[visible ? "visible" : "hidden"].filter(Boolean)} onAnimationComplete={{
      visible: () => {
        if (visibleDone) {
          return;
        }
        setVisibleDone(true);
        onVisibleDone?.();
      },
      hidden: () => {
        if (!visibleDone) {
          return;
        }
        setVisibleDone(false);
        onHiddenDone?.();
      }
    }} data-sentry-element="MotionDiv" data-sentry-source-file="index.tsx">
        {/* {immediate && (
          <motion.div variants={{ visible: { opacity: 0 }, hidden: {} }} />
         )} */}
        {cells.map(cell => {
        const coordinate = sumCoordinates(positionToCoordinate(cell.position), {
          top: -sizes.cell.rem / 2,
          left: -sizes.cell.rem / 2
        });
        return <CellModel key={cell._key} animating={animating && visibleDone} cell={cell} cellByPosition={cellByPosition} className="absolute" fill={animatingFillByKey[cell._key] ?? "unfilled"} highlighted={highlighted[cell.position[0]]?.[cell.position[1]]} immediate={immediate} muted={mutedProp} ready={visibleDone} shadow={shadow[cell.position[0]]?.[cell.position[1]]} style={{
          top: `${coordinate.top}rem`,
          left: `${coordinate.left}rem`
        }} variants={{
          hidden: {
            transition: immediate ? {
              duration: 0
            } : {
              delay: hiddenDelay[cell._key]
            }
          },
          visible: {
            transition: immediate ? {
              duration: 0
            } : {
              delay: visibleDelay[cell._key]
            }
          }
        }} getSunIndex={() => Math.abs(15 - (visuallyUnsatisfiedActiveNodes.filter(({
          sink
        }) => !sink).length - 1) % 30)} pickPlaySinkSatisfied={({
          playSinkSatisfied,
          playSinkSatisfiedEnd,
          playSinkSatisfiedEndFull
        }) => !doneAfterAnimating ? playSinkSatisfied : moves.length > bestScore ? playSinkSatisfiedEnd : playSinkSatisfiedEndFull} onAnimationComplete={definition => {
          if (definition === (animatingFillByKey[cell._key] ?? "unfilled")) {
            setVisibleNodesByFill(visibleNodesByFill => ({
              ...visibleNodesByFill,
              ...(!(cell._key in visibleFillByKey) ? {} : {
                [visibleFillByKey[cell._key]!]: visibleNodesByFill[visibleFillByKey[cell._key]!].difference(new Set([cell]))
              }),
              [definition as keyof typeof visibleNodesByFill]: visibleNodesByFill[definition as keyof typeof visibleNodesByFill].union(new Set([cell]))
            }));
          } else if (definition === "inactive" || definition === "disactive") {
            setVisibleNodesByFill(visibleNodesByFill => ({
              fire: visibleNodesByFill.fire.difference(new Set([cell])),
              sun: visibleNodesByFill.sun.difference(new Set([cell])),
              unfilled: visibleNodesByFill.unfilled.difference(new Set([cell])),
              water: visibleNodesByFill.water.difference(new Set([cell]))
            }));
          }
        }} />;
      })}
        {blocks.map(block => {
        const active = consideredActiveByKey[block._key]!;
        const adjacentBlocks = consideredAdjacentBlocksByKey[block._key]!;
        const position = block === consideredBlock && consideredPosition ? consideredPosition : blockPositionByKey[block._key]!;
        const originalPositionCoordinate = positionToCoordinate(blockPositionByKey[block._key]!);
        const positionCoordinate = position === blockPositionByKey[block._key]! ? originalPositionCoordinate : positionToCoordinate(position);
        const resolvedCoordinate = controlledBlocks[block._key]?.coordinate ?? positionCoordinate;
        const coordinate = sumCoordinates(resolvedCoordinate, {
          top: -sizes.block.rem / 2,
          left: -sizes.block.rem / 2
        });
        return <BlockModel key={block._key} active={active} animating={animating && visibleDone} block={block} fill={animatingFillByKey[block._key] ?? "unfilled"}
        // HACK Just for Moon
        fillReal={fillByKey[block._key] ?? "unfilled"} immediate={immediate} muted={mutedProp} nCount={visibleDone ? adjacentBlocks.size : 0} ready={visibleDone} shape={block.shape} className={`absolute drop-shadow-md ${!visible || !visibleDone || !canInteract || !block.mobile ? "" : block !== consideredBlock ? "cursor-grab hover:drop-shadow-lg focus:drop-shadow-lg" : "cursor-grabbing drop-shadow-xl"} ${(animatingFillByKey[block._key] ?? "unfilled") !== (visibleFillByKey[block._key] ?? "unfilled") ? "z-[70]" : controlledBlocks[block._key] ? "z-[60]" : block === hoveredBlock && !consideredBlock ? "z-50" : block === consideredBlock ? "z-40" : block.mobile ? "z-30" : "z-20"} ${!visibleDone || immediate ? "" : controlledBlocks[block._key]?.animationClassName ?? "transition-[top,left,transform]"}`} style={{
          top: `${coordinate.top}rem`,
          left: `${coordinate.left}rem`,
          rotate: block !== consideredBlock ? 0 : Math.sign(resolvedCoordinate.left - originalPositionCoordinate.left) * Math.max(0, Math.log2(Math.abs(resolvedCoordinate.left - originalPositionCoordinate.left)))
        }} variants={{
          hidden: {
            transition: immediate ? {
              duration: 0
            } : {
              delay: hiddenDelay[block._key]
            }
          },
          visible: {
            transition: immediate ? {
              duration: 0
            } : {
              delay: visibleDelay[block._key]
            }
          }
        }} getSunIndex={() => Math.abs(15 - (visuallyUnsatisfiedActiveNodes.filter(({
          sink
        }) => !sink).length - 1) % 30)} pickPlaySinkSatisfied={({
          playSinkSatisfied,
          playSinkSatisfiedEnd,
          playSinkSatisfiedEndFull
        }) => !doneAfterAnimating ? playSinkSatisfied : moves.length > bestScore ? playSinkSatisfiedEnd : playSinkSatisfiedEndFull} onAnimationComplete={definition => {
          if (definition === (animatingFillByKey[block._key] ?? "unfilled")) {
            setVisibleNodesByFill(visibleNodesByFill => ({
              ...visibleNodesByFill,
              ...(!(block._key in visibleFillByKey) ? {} : {
                [visibleFillByKey[block._key]!]: visibleNodesByFill[visibleFillByKey[block._key]!].difference(new Set([block]))
              }),
              [definition as keyof typeof visibleNodesByFill]: visibleNodesByFill[definition as keyof typeof visibleNodesByFill].union(new Set([block]))
            }));
          } else if (definition === "inactive" || definition === "disactive") {
            setVisibleNodesByFill(visibleNodesByFill => ({
              fire: visibleNodesByFill.fire.difference(new Set([block])),
              sun: visibleNodesByFill.sun.difference(new Set([block])),
              unfilled: visibleNodesByFill.unfilled.difference(new Set([block])),
              water: visibleNodesByFill.water.difference(new Set([block]))
            }));
          }
        }} {...!visible || !visibleDone || !canInteract || !block.mobile ? {} : {
          ...blockPropsForKeyboard(block, {
            position
          }),
          ...blockPropsForPointer(block, {
            position
          })
        }} />;
      })}
      </MotionDiv>
      {controls && <ButtonRow className={`fixed bottom-0 justify-center transition-opacity duration-1000 ${!visible || !visibleDone || !canInteract ? "pointer-events-none opacity-0" : "opacity-100"}`}>
          <Button className="-rotate-90" disabled={!visible || !visibleDone || !canInteract || !moves.length} title="Undo All" onClick={() => undoRedo(-moves.length)}>
            <ReplayRounded style={{
          height: sizes.control.rem * 16,
          width: sizes.control.rem * 16
        }} />
          </Button>
          <Button disabled={!visible || !visibleDone || !canInteract || !moves.length} title="Undo" onClick={() => undoRedo(-1)}>
            <UndoRounded style={{
          height: sizes.control.rem * 16,
          width: sizes.control.rem * 16
        }} />
          </Button>
          <Button disabled={!visible || !visibleDone || !canInteract || !redoMoves.length} title="Redo" onClick={() => undoRedo(1)}>
            <RedoRounded style={{
          height: sizes.control.rem * 16,
          width: sizes.control.rem * 16
        }} />
          </Button>
          <Button className="rotate-90 -scale-x-100 p-1 hover:-scale-x-110 hover:scale-y-110 focus:-scale-x-110 focus:scale-y-110 disabled:hover:-scale-x-100 disabled:hover:scale-y-100 disabled:focus:-scale-x-100 disabled:focus:scale-y-100" disabled={!visible || !visibleDone || !canInteract || !redoMoves.length} title="Redo All" onClick={() => undoRedo(redoMoves.length)}>
            <ReplayRounded style={{
          height: sizes.control.rem * 16,
          width: sizes.control.rem * 16
        }} />
          </Button>
        </ButtonRow>}
    </div>;
};
export const Level = memo(LevelPure);