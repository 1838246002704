// TODO Better Name for file
import {
  defaults,
  flatMap,
  groupBy,
  keyBy,
  map,
  mapValues,
  pick,
  reduce,
} from "lodash/fp";

import { queue, queueSet, reduceAcc, unflow } from "@sunblocks/utils";

import type { Block, Fill, Level, Move, Node } from "./data";
import { sumPositions, toPositionMap } from "./position";
import type { Position, PositionMap } from "./position";

export const getCellByPosition = ({ cells }: Pick<Level, "cells">) =>
  toPositionMap(
    cells,
    ({ position }) => position,
    (cell) => cell
  );

export const getSinksByFill = ({
  blocks,
  cells,
}: Pick<Level, "blocks" | "cells">) =>
  unflow(
    [...blocks, ...cells],
    groupBy(({ sink }) => sink),
    mapValues((nodes) => new Set(nodes)),
    pick(["sun", "unfilled", "water"]),
    defaults({
      sun: new Set<Node>(),
      unfilled: new Set<Node>(),
      water: new Set<Node>(),
    })
  );

export const getSourcesByFill = ({
  blocks,
  cells,
}: Pick<Level, "blocks" | "cells">) =>
  unflow(
    [...blocks, ...cells],
    groupBy(({ source }) => source),
    mapValues((nodes) => new Set(nodes)),
    pick(["fire", "sun", "water"]),
    defaults({
      fire: new Set<Node>(),
      sun: new Set<Node>(),
      water: new Set<Node>(),
    })
  );

export const getBlockPositionByKey = (
  { blocks }: Pick<Level, "blocks">,
  moves: Move[]
) =>
  unflow(
    blocks,
    keyBy(({ _key }) => _key),
    mapValues(({ initialPosition: position }) => position),
    reduceAcc(
      (acc, { _key, position }) => ({ ...acc, [_key]: position }),
      moves
    )
  );

export const getBlockByPosition = (
  { blocks }: Pick<Level, "blocks">,
  {
    blockPositionByKey,
  }: { blockPositionByKey: ReturnType<typeof getBlockPositionByKey> }
) =>
  toPositionMap(
    blocks,
    ({ _key, shape }) =>
      shape.map((position) =>
        sumPositions(position, blockPositionByKey[_key]!)
      ),
    (block) => block
  );

export const getAdjacentBlocksByKey = (
  { blocks }: Pick<Level, "blocks">,
  {
    blockByPosition,
    blockPositionByKey,
  }: {
    blockByPosition: ReturnType<typeof getBlockByPosition>;
    blockPositionByKey: ReturnType<typeof getBlockPositionByKey>;
  }
) =>
  unflow(
    blocks,
    keyBy(({ _key }) => _key),
    mapValues((block) => {
      const { _key, shape } = block;
      const [y, x] = blockPositionByKey[_key]!;

      const adjacentBlocks = new Set<Block>(
        shape
          .flatMap((position) => [
            blockByPosition[y + position[0] - 1]?.[x + position[1]],
            blockByPosition[y + position[0] + 1]?.[x + position[1]],
            blockByPosition[y + position[0]]?.[x + position[1] - 1],
            blockByPosition[y + position[0]]?.[x + position[1] + 1],
          ])
          .filter(Boolean)
      );

      adjacentBlocks.delete(block);

      return adjacentBlocks;
    })
  );

export const getAdjacentNodesByKey = (
  { blocks, cells }: Pick<Level, "blocks" | "cells">,
  {
    adjacentBlocksByKey,
    blockByPosition,
    blockPositionByKey,
    cellByPosition,
  }: {
    adjacentBlocksByKey: ReturnType<typeof getAdjacentBlocksByKey>;
    blockByPosition: ReturnType<typeof getBlockByPosition>;
    blockPositionByKey: ReturnType<typeof getBlockPositionByKey>;
    cellByPosition: ReturnType<typeof getCellByPosition>;
  }
) => ({
  ...unflow(
    cells,
    keyBy(({ _key }) => _key),
    mapValues(
      ({ position }) =>
        new Set<Node>(
          [
            cellByPosition[position[0] - 1]?.[position[1]],
            cellByPosition[position[0] + 1]?.[position[1]],
            cellByPosition[position[0]]?.[position[1] - 1],
            cellByPosition[position[0]]?.[position[1] + 1],
            blockByPosition[position[0]]?.[position[1]],
          ].filter(Boolean)
        )
    )
  ),
  ...unflow(
    blocks,
    keyBy(({ _key }) => _key),
    mapValues(({ _key, shape }) => {
      const position = blockPositionByKey[_key]!;
      const adjacentBlocks = adjacentBlocksByKey[_key]!;

      return new Set<Node>(
        [
          ...adjacentBlocks,
          ...shape.map(
            (shapePosition) =>
              cellByPosition[shapePosition[0] + position[0]]?.[
                shapePosition[1] + position[1]
              ]
          ),
        ].filter(Boolean)
      );
    })
  ),
});

export const getActiveByKey = (
  { blocks, cells }: Pick<Level, "blocks" | "cells">,
  {
    adjacentBlocksByKey,
    adjacentNodesByKey,
  }: {
    adjacentBlocksByKey: ReturnType<typeof getAdjacentBlocksByKey>;
    adjacentNodesByKey: ReturnType<typeof getAdjacentNodesByKey>;
  }
) => {
  const cellActiveByKey = unflow(
    cells,
    keyBy(({ _key }) => _key),
    mapValues(({ active }) => active)
  );

  return {
    ...cellActiveByKey,
    ...unflow(
      blocks,
      keyBy(({ _key }) => _key),
      mapValues((block) => {
        const { active, _key, n, weak } = block;

        const adjacentBlocks = adjacentBlocksByKey[_key]!;

        return {
          _key,
          active:
            active ??
            (n !== undefined
              ? adjacentBlocks.size < n
                ? "inactive"
                : n < adjacentBlocks.size
                ? "disactive"
                : "active"
              : weak
              ? // In the next step, we use undefined to know it was weak and do something about it
                undefined
              : "active"),
        };
      }),
      // eslint-disable-next-line lodash-fp/no-extraneous-function-wrapping -- EXPECTED Needed for scope
      (activeByKey) =>
        mapValues(({ _key, active }) => {
          const adjacentNodes = [...adjacentNodesByKey[_key]!];

          return (
            active ??
            (() => {
              const strongestNeighbor = active
                ? undefined
                : adjacentNodes.find(
                    (node) =>
                      !("weak" in node && node.weak) &&
                      !node.source &&
                      !node.sink &&
                      (activeByKey[node._key]?.active ??
                        cellActiveByKey[node._key]) === "disactive"
                  ) ??
                  adjacentNodes.find(
                    (node) =>
                      !("weak" in node && node.weak) &&
                      !node.source &&
                      !node.sink &&
                      (activeByKey[node._key]?.active ??
                        cellActiveByKey[node._key]) === "active"
                  );

              return (
                strongestNeighbor &&
                (activeByKey[strongestNeighbor._key]?.active ??
                  cellActiveByKey[strongestNeighbor._key])
              );
            })() ??
            "inactive"
          );
        }, activeByKey)
    ),
  };
};

export const getActiveNodes = (
  { blocks, cells }: Pick<Level, "blocks" | "cells">,
  { activeByKey }: { activeByKey: ReturnType<typeof getActiveByKey> }
) =>
  new Set(
    [...blocks, ...cells].filter((node) => activeByKey[node._key] === "active")
  );

export const getFillsByKey = ({
  activeByKey,
  activeNodes,
  adjacentNodesByKey,
}: {
  activeByKey: ReturnType<typeof getActiveByKey>;
  activeNodes: ReturnType<typeof getActiveNodes>;
  adjacentNodesByKey: ReturnType<typeof getAdjacentNodesByKey>;
}) =>
  unflow(
    activeNodes,
    queueSet(
      (nodeGroups, nextNode: Node, { remove }) => [
        ...nodeGroups,
        queue(
          (acc, adjacentNode: Node, { push }): typeof acc => {
            if (acc.group.has(adjacentNode)) {
              return acc;
            }

            remove(adjacentNode);
            const { _key, active, source } = adjacentNode;

            if (
              (_key in activeByKey ? activeByKey[_key] : active) !== "active"
            ) {
              return acc;
            }

            acc.group.add(adjacentNode);

            push(...adjacentNodesByKey[_key]!);

            return {
              fills: !source ? acc.fills : acc.fills.add(source),
              group: acc.group.add(adjacentNode),
            };
          },
          {
            fills: new Set<NonNullable<Node["source"]>>(),
            group: new Set<Node>(),
          }
        )([nextNode]),
      ],
      [] as {
        fills: Set<NonNullable<Node["source"]>>;
        group: Set<Node>;
      }[]
    ),
    map(({ group, fills }) =>
      unflow(
        [...group],
        keyBy(({ _key }) => _key),
        mapValues(() => fills)
      )
    ),
    reduce(
      (acc, value) => ({ ...acc, ...value }),
      {} as { [id: string]: Set<NonNullable<Node["source"]>> }
    )
  );

export const getFillByKey = ({
  fillsByKey,
}: {
  fillsByKey: ReturnType<typeof getFillsByKey>;
}) =>
  mapValues(
    (fills) =>
      fills.has("water")
        ? ("water" as const)
        : fills.has("fire")
        ? ("fire" as const)
        : fills.has("sun")
        ? ("sun" as const)
        : ("unfilled" as const),
    fillsByKey
  );

export const getNodesByFill = ({
  activeNodes,
  fillByKey,
}: {
  activeNodes: ReturnType<typeof getActiveNodes>;
  fillByKey: ReturnType<typeof getFillByKey>;
}) =>
  unflow(
    [...activeNodes],
    groupBy(({ _key }) => fillByKey[_key]!),
    mapValues((nodes) => new Set(nodes)),
    pick(["fire", "sun", "unfilled", "water"]),
    defaults({
      fire: new Set<Node>(),
      sun: new Set<Node>(),
      unfilled: new Set<Node>(),
      water: new Set<Node>(),
    })
  );

export const isWin = ({
  nodesByFill,
  sinksByFill,
}: {
  nodesByFill: ReturnType<typeof getNodesByFill>;
  sinksByFill: ReturnType<typeof getSinksByFill>;
}) =>
  (Object.keys(sinksByFill) as (keyof typeof sinksByFill)[]).every(
    (fill) =>
      // Usually I could just difference the sets, but in aStar I'm modifying the nodes, so let's check their _keys instead
      !new Set([...sinksByFill[fill]].map(({ _key }) => _key)).difference(
        new Set([...nodesByFill[fill]].map(({ _key }) => _key))
      ).size
  );

export const getNodesByFillOfSource = ({
  activeNodes,
  fillsByKey,
}: {
  activeNodes: ReturnType<typeof getActiveNodes>;
  fillsByKey: ReturnType<typeof getFillsByKey>;
}) =>
  unflow(
    [...activeNodes],
    flatMap((node) =>
      [...(fillsByKey[node._key] ?? [])].map(
        (fill) => [fill, node] satisfies [Fill, Node]
      )
    ),
    groupBy(([fill]) => fill),
    mapValues((pairs) => new Set(pairs.map(([, node]) => node))),
    pick(["fire", "sun", "water"]),
    defaults({
      fire: new Set<Node>(),
      sun: new Set<Node>(),
      water: new Set<Node>(),
    })
  );

// TODO Currently, this ignores the redoMoves completely
export const optimizeMoveSet = (
  { blocks }: Pick<Level, "blocks">,
  move: Move,
  moveSetInitial: [Move[], Move[]] = [[], []]
): [Move[], Move[]] => {
  let moves = moveSetInitial[0];
  if (moves.at(-1)?._key === move._key) {
    moves = moves.slice(0, -1);
  }

  const nextMoves = [...moves, move];
  const prevBlockPositionByKey = getBlockPositionByKey({ blocks }, moves);
  const nextBlockPositionByKey = getBlockPositionByKey({ blocks }, nextMoves);

  return [
    prevBlockPositionByKey[move._key]![0] ===
      nextBlockPositionByKey[move._key]![0] &&
    prevBlockPositionByKey[move._key]![1] ===
      nextBlockPositionByKey[move._key]![1]
      ? moves
      : nextMoves,
    [],
  ];
};

export const getValidPositions = (
  block: Pick<Block, "_key" | "mobile" | "shape">,
  position: Position,
  {
    blockByPosition,
    cellByPosition,
  }: {
    blockByPosition: ReturnType<typeof getBlockByPosition>;
    cellByPosition: ReturnType<typeof getCellByPosition>;
  }
) =>
  !block.mobile
    ? []
    : queue(
        (acc, position: Position, { push }) => {
          const [y, x] = position;

          if (acc.visited[y]?.[x]) {
            return acc;
          }

          const visited = {
            ...acc.visited,
            [y]: { ...acc.visited[y], [x]: true as const },
          };

          if (
            block.shape.some(
              (position) =>
                !cellByPosition[y + position[0]]?.[x + position[1]] ||
                ![undefined, block].includes(
                  blockByPosition[y + position[0]]?.[x + position[1]]
                )
            )
          ) {
            return { ...acc, visited };
          }

          push([y - 1, x], [y + 1, x], [y, x - 1], [y, x + 1]);

          return { ...acc, visited, positions: [...acc.positions, position] };
        },
        {
          positions: [] as Position[],
          visited: {} as PositionMap<true>,
        }
      )([position]).positions;

export const isValidMoves = (
  { blocks }: Pick<Level, "blocks">,
  {
    cellByPosition,
  }: {
    cellByPosition: ReturnType<typeof getCellByPosition>;
  },
  moves: Move[]
) => {
  const blockByKey = keyBy(({ _key }) => _key, blocks);

  return moves.every(({ _key, position: [y, x] }, index) => {
    const block = blockByKey[_key];
    const blockPositionByKey = getBlockPositionByKey(
      { blocks },
      moves.slice(0, index)
    );

    return (
      block &&
      toPositionMap(
        getValidPositions(block, blockPositionByKey[block._key]!, {
          cellByPosition,
          blockByPosition: getBlockByPosition(
            { blocks },
            { blockPositionByKey }
          ),
        }),
        (position) => position,
        () => true as const
      )[y]?.[x]
    );
  });
};

export const isBlocksOverlapping = (
  blocks: Block[],
  {
    blockPositionByKey,
  }: { blockPositionByKey: ReturnType<typeof getBlockPositionByKey> }
) =>
  blocks.reduce((occupied: Set<string> | true, { _key, shape }) => {
    if (occupied === true) {
      return occupied;
    }

    const position = blockPositionByKey[_key]!;

    const shapeTruePositions = shape.map(
      ([y, x]) => [position[0] + y, position[1] + x] satisfies Position
    );

    return shapeTruePositions.some(([y, x]) => occupied.has([y, x].join(",")))
      ? true
      : shapeTruePositions.reduce((occupied, position) => {
          occupied.add(position.join(","));
          return occupied;
        }, occupied);
  }, new Set<string>()) === true;
