// eslint-disable-next-line @typescript-eslint/ban-ts-comment -- EXPECTED The shims need it
// @ts-nocheck
import { flow, isUndefined, omitBy, reduce } from "lodash/fp";
import difference from "set.prototype.difference";
import intersection from "set.prototype.intersection";
import union from "set.prototype.union";

// TODO [engine:node@>=22.0.0]: Set methods
difference.shim();
intersection.shim();
union.shim();

export const unflow: {
  <A, R1, R2, R3, R4, R5, R6, R7>(
    arg: A,
    f1: (arg: A) => R1,
    f2: (a: R1) => R2,
    f3: (a: R2) => R3,
    f4: (a: R3) => R4,
    f5: (a: R4) => R5,
    f6: (a: R5) => R6,
    f7: (a: R6) => R7
  ): R7;
  <A, R1, R2, R3, R4, R5, R6, R7>(
    arg: A,
    f1: (arg: A) => R1,
    f2: (a: R1) => R2,
    f3: (a: R2) => R3,
    f4: (a: R3) => R4,
    f5: (a: R4) => R5,
    f6: (a: R5) => R6,
    f7: (a: R6) => R7,
    ...fns: ((a: any) => any)[]
  ): any;
  <A, R1, R2, R3, R4, R5, R6>(
    arg: A,
    f1: (arg: A) => R1,
    f2: (a: R1) => R2,
    f3: (a: R2) => R3,
    f4: (a: R3) => R4,
    f5: (a: R4) => R5,
    f6: (a: R5) => R6
  ): R6;
  <A, R1, R2, R3, R4, R5>(
    arg: A,
    f1: (arg: A) => R1,
    f2: (a: R1) => R2,
    f3: (a: R2) => R3,
    f4: (a: R3) => R4,
    f5: (a: R4) => R5
  ): R5;
  <A, R1, R2, R3, R4>(
    arg: A,
    f1: (arg: A) => R1,
    f2: (a: R1) => R2,
    f3: (a: R2) => R3,
    f4: (a: R3) => R4
  ): R4;
  <A, R1, R2, R3>(
    arg: A,
    f1: (arg: A) => R1,
    f2: (a: R1) => R2,
    f3: (a: R2) => R3
  ): R3;
  <A, R1, R2>(arg: A, f1: (arg: A) => R1, f2: (a: R1) => R2): R2;
  <A>(arg: A, ...fns: ((a: any) => any)[]): any;
} = <A>(arg: A, ...fns: ((a: any) => any)[]) => flow(...fns)(arg);

export const reduceAcc =
  <Acc, T>(callback: (acc: Acc, value: T) => Acc, array: T[]) =>
  (acc: Acc) =>
    reduce(callback, acc, array);

export const omitUndefined = <T>(object: T) => omitBy(isUndefined, object) as T;

export const buildTraversal =
  (getAndRemoveOne: (value: T[]) => T) =>
  <T, S>(
    initial: Iterable<T>,
    initialAcc: S,
    callback: (
      acc: S,
      value: T,
      methods: { done: () => T[]; push: (...values: T[]) => any }
    ) => S
  ) => {
    const remaining = [...initial];
    let acc = initialAcc;

    while (remaining.length) {
      const value = getAndRemoveOne(remaining);
      let done = false;
      acc = callback(acc, value, {
        done: () => {
          done = true;
          return remaining;
        },
        push: (...values: T[]) => remaining.push(...values),
      });

      if (done) {
        return acc;
      }
    }

    return acc;
  };

export const traverse =
  <Item, Collection>(
    transform: (collection: Collection) => {
      getAndRemoveOne: () => Item;
      getCollection: () => collection;
      push: (...items: Item[]) => void;
      remove: (...items: Item[]) => void;
      size: () => number;
    }
  ) =>
  <Acc>(
    callback: (
      acc: Acc,
      value: Item,
      methods: {
        done: () => Item[];
        push: (...items: Item[]) => void;
        remove: (...items: Item[]) => void;
      }
    ) => Acc,
    initialAcc: Acc
  ) =>
  <Item, Collection>(collection: Collection) => {
    const { getAndRemoveOne, getCollection, push, remove, size } = transform<
      Item,
      Collection
    >(collection);
    let acc = initialAcc;

    while (size()) {
      const value = getAndRemoveOne();
      let done = false;
      acc = callback(acc, value, {
        push,
        remove,
        done: () => {
          done = true;
          return getCollection();
        },
      });

      if (done) {
        return acc;
      }
    }

    return acc;
  };

export const queue = traverse<Item, Item[]>((initial) => {
  const queue = [...initial];

  return {
    getAndRemoveOne: () => queue.shift()!,
    getCollection: () => queue,
    push: (...items) => queue.push(...items),
    size: () => queue.length,
    remove: (...items) =>
      items.forEach((item) => {
        const index = queue.indexOf(item);
        if (index !== -1) {
          queue.splice(index, 1);
        }
      }),
  };
});

export const queueSet = traverse<Item, Set<Item>>((initial) => {
  const queue = new Set(initial);

  return {
    getAndRemoveOne: () => queue.values().next().value!,
    getCollection: () => queue,
    push: (...items) => items.forEach((item) => queue.add(item)),
    remove: (...items) => items.forEach((item) => queue.delete(item)),
    size: () => queue.size,
  };
});
