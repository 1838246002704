import AppsRounded from "@mui/icons-material/AppsRounded";
import VolumeOffRounded from "@mui/icons-material/VolumeOffRounded";
import VolumeUpRounded from "@mui/icons-material/VolumeUpRounded";
import type { ComponentProps, Dispatch, SetStateAction } from "react";
import { Button } from "../Button";
import { ButtonRow } from "../ButtonRow";
import { sizes } from "../sizes";

// import { useHowler } from "../use-howler";

export const TopControls = ({
  className,
  muted,
  onBack,
  setMuted,
  ...props
}: ComponentProps<typeof ButtonRow> & {
  muted: boolean;
  onBack?: 0 | false | (() => void);
  setMuted: Dispatch<SetStateAction<boolean>>;
}) => {
  const VolumeIcon = muted ? VolumeOffRounded : VolumeUpRounded;
  return <ButtonRow className={`fixed top-0 flex flex-row ${className}`} {...props} data-sentry-element="ButtonRow" data-sentry-component="TopControls" data-sentry-source-file="index.tsx">
      {onBack && <Button tabIndex={1} disabled={!onBack} title="Back" onClick={onBack}>
          <AppsRounded style={{
        height: sizes.control.rem * 16,
        width: sizes.control.rem * 16
      }} />
        </Button>}
      <div className="grow" />
      <Button tabIndex={1} title="Mute" onClick={() => setMuted(muted => !muted)} data-sentry-element="Button" data-sentry-source-file="index.tsx">
        <VolumeIcon style={{
        height: sizes.control.rem * 16,
        width: sizes.control.rem * 16
      }} data-sentry-element="VolumeIcon" data-sentry-source-file="index.tsx" />
      </Button>
    </ButtonRow>;
};
export const startTabIndex = 2;