import { useEffect } from "react";
import type { ComponentProps } from "react";

import type { Block, Level, Position } from "@sunblocks/game";

import type { BlockModel } from "../BlockModel";

export const useKeyboardController = ({
  consideredBlock,
  consideringController,
  onBlockCommit,
  onBlockConsider,
  onBlockHover,
  undoRedo,
  tabIndexOffset = 0,
  level: { gridWidth },
}: {
  consideredBlock: Block | undefined;
  consideringController: string | undefined;
  level: Pick<Level, "gridWidth">;
  onBlockCommit: () => void;
  onBlockConsider: (
    ...args: [] | [controller: "keyboard", block: Block, position: Position]
  ) => void;
  onBlockHover: (block: Block | undefined) => void;
  tabIndexOffset?: number;
  undoRedo: (numMoves: number) => void;
}) => {
  useEffect(() => {
    const callback = (event: KeyboardEvent) => {
      const { ctrlKey, key, metaKey, shiftKey } = event;
      if ((!metaKey && !ctrlKey) || (key !== "y" && key !== "z")) {
        return;
      }

      event.preventDefault();

      ({
        false: {
          y: () => undoRedo(1),
          z: () => undoRedo(-1),
        },
        true: {
          z: () => undoRedo(1),
        },
      })[`${shiftKey}`][key]?.();
    };

    document.addEventListener("keydown", callback);
    return () => document.removeEventListener("keydown", callback);
  }, [undoRedo]);

  return {
    blockProps: (
      block: Block,
      { position, position: [y, x] }: { position: Position }
    ) =>
      ({
        tabIndex: y * gridWidth + x + tabIndexOffset,
        onFocus: () => onBlockHover(block),
        onBlur: () => {
          // HACK This will only commit/cancel a keyboard consideration
          // The issue is that we can't tell if we focus/blur from keyboard or mouse
          // Any solution for this is a bad hack
          if (consideredBlock && consideringController === "keyboard") {
            onBlockCommit();
          }
          onBlockHover(undefined);
        },
        onKeyDown: ({ altKey, ctrlKey, metaKey, shiftKey, key }) => {
          if (altKey || ctrlKey || metaKey || shiftKey) {
            return;
          }

          if (
            !consideredBlock &&
            (key === "Enter" ||
              key === " " ||
              key === "ArrowUp" ||
              key === "ArrowDown" ||
              key === "ArrowLeft" ||
              key === "ArrowRight")
          ) {
            onBlockConsider("keyboard", block, position);
          }

          if (key === "Enter" || key === " ") {
            if (!consideredBlock) {
              onBlockConsider("keyboard", block, position);
            } else {
              onBlockCommit();
            }
            return;
          }

          if (
            key === "ArrowUp" ||
            key === "ArrowDown" ||
            key === "ArrowLeft" ||
            key === "ArrowRight"
          ) {
            onBlockConsider(
              "keyboard",
              block,
              (
                {
                  ArrowUp: [y - 1, x],
                  ArrowDown: [y + 1, x],
                  ArrowLeft: [y, x - 1],
                  ArrowRight: [y, x + 1],
                } satisfies { [key: string]: Position }
              )[key]
            );
            return;
          }

          if (consideredBlock && key === "Escape") {
            onBlockConsider();
          }
        },
      } satisfies Partial<ComponentProps<typeof BlockModel>>),
  };
};
