import { useEffect, useState } from "react";

export const useWindowDimensions = () => {
  const [windowDimensions, setWindowDimensions] = useState({
    height: 0,
    width: 0,
  });

  useEffect(() => {
    const callback = () =>
      setWindowDimensions({
        height: window.innerHeight,
        width: window.innerWidth,
      });

    callback();
    globalThis.addEventListener("orientationchange", callback);
    globalThis.addEventListener("resize", callback);
    return () => {
      globalThis.removeEventListener("orientationchange", callback);
      globalThis.removeEventListener("resize", callback);
    };
  }, []);

  return windowDimensions;
};
