import { useCallback, useState } from "react";

export const useVibrate = <Fn extends (...any: any[]) => any>(
  fn: Fn,
  pattern: VibratePattern
): Fn => {
  const [fixedPattern] = useState(() => pattern);

  const vibratedFn = useCallback(
    (...args: Parameters<Fn>) => {
      navigator.vibrate(fixedPattern);
      return fn(...args) as ReturnType<Fn>;
    },
    [fixedPattern, fn]
  ) as Fn;

  return "vibrate" in navigator ? vibratedFn : fn;
};
