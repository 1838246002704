import { useEffect, useMemo } from "react";

import type {
  Block,
  Move,
  Position,
  getBlockPositionByKey,
} from "@sunblocks/game";

import { useDocumentVisibility } from "../use-document-visibility-state";
import { useWindowFocus } from "../use-window-focus";

export const useMoveHintsController = ({
  blockPositionByKey,
  blocks,
  consideringController,
  moveHints,
  onBlockConsider,
  ready,
  consideredBlock: consideredBlockRaw,
}: {
  blockPositionByKey: ReturnType<typeof getBlockPositionByKey>;
  blocks: Block[];
  consideredBlock: Block | undefined;
  consideringController: string | undefined;
  moveHints: Move[];
  onBlockConsider: (
    ...args: [] | [controller: "moveHints", block: Block, position: Position]
  ) => void;
  ready: boolean;
}) => {
  const consideredBlock =
    consideringController !== "moveHints" ? undefined : consideredBlockRaw;

  const nextMoveHint = useMemo(
    () =>
      moveHints.find(
        ({ _key, position }) =>
          blockPositionByKey[_key]![0] !== position[0] ||
          blockPositionByKey[_key]![1] !== position[1]
      ),
    [blockPositionByKey, moveHints]
  );

  const visibilityState = useDocumentVisibility();
  const windowFocus = useWindowFocus();
  useEffect(() => {
    if (
      !ready ||
      !windowFocus ||
      visibilityState !== "visible" ||
      !nextMoveHint
    ) {
      return () => {};
    }

    if (consideredBlock) {
      if (consideringController !== "moveHints") {
        return () => {};
      }

      const timeout = setTimeout(onBlockConsider, 1000);

      return () => clearTimeout(timeout);
    }

    const { _key, position } = nextMoveHint;

    const timeout = setTimeout(
      () =>
        onBlockConsider(
          "moveHints",
          blocks.find((block) => block._key === _key)!,
          position
        ),
      3000
    );

    return () => clearTimeout(timeout);
  }, [
    blocks,
    consideredBlock,
    consideringController,
    nextMoveHint,
    onBlockConsider,
    ready,
    visibilityState,
    windowFocus,
  ]);

  return {
    controlledBlocks: useMemo(
      () => ({
        ...(!consideredBlock || consideringController !== "moveHints"
          ? {}
          : {
              [consideredBlock._key]: {
                animationClassName:
                  "transition-[top,left,transform] duration-[1000ms]",
                coordinate: undefined,
              },
            }),
      }),
      [consideredBlock, consideringController]
    ),
  };
};
